import React, { useState } from "react";
import { BrowserRouter, Route, Link, Routes } from "react-router-dom";
import Login from "./components/Login";
import Header from "./components/header";
import Home from "./components/home";
import Footer from "./components/footer";
import RecruiterHome from "./components/recruiterHome";
import SignUp from "./components/SignUp";
import Companyhome from "./components/companyhome";
import JobPostAd from "./components/jobpost";
import Blog from "./components/blog";
import ContactUs from "./components/ContactUs";
import Help from "./components/help";
import Overview from "./components/overview";
import Services from "./components/services";
import Terms from "./components/terms";
import AboutUs from "./components/aboutUs";

const App = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <BrowserRouter>
      <div>
        <nav>
          <ul>
            <li>
              <Link to="/"></Link>
            </li>
            <li>
              <Link to="/login"></Link>
            </li>
            <li>
              <Link to="/job"></Link>
            </li>
            <li>
              <Link to="/recruiter"></Link>
            </li>
            <li>
              <Link to="/header"></Link>
            </li>
            <li>
              <Link to="/footer"></Link>
            </li>
            <li>
              <Link to="/signup"></Link>
            </li>
            <li>
              <Link to="/companies"></Link>
            </li>
            <li>
              <Link to="/blog"></Link>
            </li>
            <li>
              <Link to="/contactUs"></Link>
            </li>
            <li>
              <Link to="/help"></Link>
            </li>
            <li>
              <Link to="/overview"></Link>
            </li>
            <li>
              <Link to="/services"></Link>
            </li>
            <li>
              <Link to="/terms"></Link>
            </li>
            <li>
              <Link to="/aboutUs"></Link>
            </li>
          </ul>
        </nav>

        <Routes>
          <Route path="/login" element={<Login />} />
          <Route exact path="/" element={<Home />} />
          <Route path="/header" element={<Header />} />
          <Route path="/footer" element={<Footer />} />
          <Route path="/companies" element={<Companyhome />} />
          <Route path="/recruiter" element={<RecruiterHome />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/job" element={<JobPostAd />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/help" element={<Help />} />
          <Route path="/overview" element={<Overview />} />
          <Route path="/services" element={<Services />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/aboutUs" element={<AboutUs />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
