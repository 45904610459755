import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fortawesome/fontawesome-free/css/all.css";

const techFunctions = [
  {
    title: "Frontend/Backend",
    icon: "fas fa-code",
    color: "text-blue-500",
  },
  {
    title: "Android/iOS",
    icon: "fab fa-android",
    color: "text-green-500",
  },
  {
    title: "Data Science",
    icon: "fas fa-robot",
    color: "text-purple-500",
  },
  {
    title: "DevOps/Cloud",
    icon: "fas fa-cloud",
    color: "text-blue-300",
  },
  {
    title: "QA/Testing",
    icon: "fas fa-vial",
    color: "text-red-500",
  },
];

const TechFunctionsSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="p-4 mb-12">
      <h2 className="text-center text-4xl font-bold mb-8 space-x-4">
        Reach out to quality candidates across all top tech functions
      </h2>
      <Slider {...settings}>
        {techFunctions.map((tech, index) => (
          <div key={index} className="p-1">
            <div className="bg-white p-8 pb-16 pt-16 m-4 rounded-lg shadow-lg flex flex-col items-center justify-center transition-transform transform hover:scale-105 hover:bg-gray-100">
              <i className={`${tech.icon} ${tech.color} text-4xl mb-2`}></i>
              <p className="text-center text-sm">{tech.title}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TechFunctionsSlider;
