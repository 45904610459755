import React from "react";

const JobPostAd = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-4 md:flex-row md:space-x-8">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-sm mb-8 md:mb-0">
        <h2 className="text-lg font-semibold mb-4">Jobs (3)</h2>
        <div className="space-y-4">
          <div className="bg-green-50 p-4 rounded-md">
            <h3 className="font-semibold">
              Frontend Developer |{" "}
              <span className="text-green-500">Active</span>
            </h3>
            <p>Narayana guda, Hyderabad </p>
            <p>Posted on: Nov 30, 2022</p>
            <p>Onsite/Remote</p>
          </div>
          <div className="bg-yellow-50 p-4 rounded-md">
            <h3 className="font-semibold">
              Backend Developer |{" "}
              <span className="text-yellow-500">Under review</span>
            </h3>
            <p>Narayana guda, Hyderabad </p>
            <p>Posted on: Nov 30, 2022</p>
            <p>Onsite/Remote</p>
          </div>
          <div className="bg-red-50 p-4 rounded-md">
            <h3 className="font-semibold">
              Machine Learning | <span className="text-red-500">Expired</span>
            </h3>
            <p>Narayana guda, Hyderabad</p>
            <p> Posted on: Nov 30, 2022</p>
            <p>Onsite/Remote</p>
          </div>
        </div>
      </div>
      <div className="text-center md:text-left">
        <h2 className="text-2xl md:text-3xl font-semibold mb-4 text-blue-500">
          Get Started with Recruitkart
        </h2>
        <h3 className="text-4xl md:text-6xl font-bold mb-6">
          Post a Job in Minutes
        </h3>
        <ul className="space-y-4 mb-6">
          <li className="flex items-center space-x-2 border-2 border-gray-200 p-2 rounded-full w-64 md:w-72 mx-auto md:mx-0 bg-blue-50">
            <span className="flex items-center justify-center bg-blue-500 text-white p-2 rounded-full w-6 h-6">
              ✓
            </span>
            <span>Get Unlimited Recruiters</span>
          </li>
          <li className="flex items-center space-x-2 border-2 border-gray-200 p-2 rounded-full w-64 md:w-72 mx-auto md:mx-0 bg-blue-50">
            <span className="flex items-center justify-center bg-blue-500 text-white p-2 rounded-full w-6 h-6">
              ✓
            </span>
            <span>10X Higher Relevancy</span>
          </li>
          <li className="flex items-center space-x-2 border-2 border-gray-200 p-2 rounded-full w-64 md:w-72 mx-auto md:mx-0 bg-blue-50">
            <span className="flex items-center justify-center bg-blue-500 text-white p-2 rounded-full w-6 h-6">
              ✓
            </span>
            <span>Effortless Job Posting</span>
          </li>
        </ul>
        <button className="bg-blue-500 text-white px-6 py-2 rounded-lg font-semibold">
          Post a Job Now
        </button>
      </div>
    </div>
  );
};

export default JobPostAd;
