import React from "react";
import { FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-blue-900 text-white py-4">
      <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-start mt-8">
        <div className="mb-4 md:mb-0 w-full md:w-1/3">
          <h3 className="text-2xl font-semibold">Recruitkart</h3>
          <p className="mt-2 text-sm">
            Facilitating seamless connections, RecruitKart empowers recruiters
            to efficiently match candidates with job openings, simplifying the
            application process for all parties involved. We understand the
            challenges of modern recruitment and are dedicated to making the
            process as smooth and efficient as possible.
          </p>
        </div>
        <div className="flex flex-col md:flex-row w-full md:w-2/3 justify-center">
          <div className="mb-4 md:mb-0 md:mr-32">
            <h4 className="font-semibold mr-24 text-lg">Links</h4>
            <ul className="mt-2 space-y-1 text-sm">
              <li>
                <Link to="/" className="hover:underline">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/aboutUs" className="hover:underline">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contactUs" className="hover:underline">
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/services" className="hover:underline">
                  Services
                </Link>
              </li>
            </ul>
          </div>
          <div className="mb-4 md:mb-0 md:mr-4">
            <h4 className="font-semibold text-lg">Legal</h4>
            <ul className="mt-2 space-y-1 text-sm">
              <li>
                <Link to="/terms" className="hover:underline">
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link to="/help" className="hover:underline">
                  Help
                </Link>
              </li>
              <li>
                <Link to="/overview" className="hover:underline">
                  Overview
                </Link>
              </li>
              <li>
                <Link to="/blog" className="hover:underline">
                  Blog
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 flex flex-col items-center mt-8 mb-4">
        <h4 className="font-semibold mb-2 text-xl">Follow us on</h4>
        <div className="flex space-x-4">
          <a href="#facebook" className="hover:underline">
            <FaFacebook size={24} alt="Facebook" />
          </a>
          <a href="#twitter" className="hover:underline">
            <FaTwitter size={24} alt="Twitter" />
          </a>
          <a href="#linkedin" className="hover:underline">
            <FaLinkedin size={24} alt="LinkedIn" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
