import React, { useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import AOS from "aos";
import "aos/dist/aos.css";

const AboutUs = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen">
      <section>
        <Header />
      </section>
      <section
        className="relative flex flex-col items-center justify-center text-center h-screen bg-cover bg-center"
        style={{ backgroundImage: 'url("/images/aboutUs.jpg")' }}
        data-aos="fade-up"
      >
        <div className="absolute inset-0 bg-black opacity-70"></div>
        <div className="relative z-10">
          <h1 className="text-3xl md:text-7xl font-bold text-white mb-8 md:mb-12">
            Welcome to RecruitKart
          </h1>
          <p className="text-lg md:text-2xl text-white mb-8 md:mb-12">
            Enhancing Quality in Company Recruitment
          </p>
          <a
            href="#about"
            className="bg-blue-600 text-white hover:bg-blue-800 py-2 px-6 mt-4 inline-block rounded-full font-semibold transition duration-300"
          >
            Learn More
          </a>
        </div>
      </section>

      <section
        id="about"
        className="py-8 md:py-16 px-4 md:px-8 flex flex-col items-center justify-center"
        data-aos="fade-up"
      >
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-8">Who We Are</h2>
          <div className="max-w-4xl mx-auto">
            <p className="text-lg leading-relaxed mb-8">
              RecruitKart is a revolutionary platform designed to transform the
              way companies recruit talent. We understand the challenges faced
              by businesses in finding the right candidates for their job
              openings, and we aim to simplify this process by connecting
              companies with top-notch recruiters.
            </p>
            <p className="text-lg leading-relaxed mb-8">
              At RecruitKart, companies can create detailed profiles and post
              job openings across various industries and job roles. Our
              extensive network of recruiters, who are experts in their
              respective domains, then review these job postings and identify
              the most suitable candidates.
            </p>
          </div>
        </div>
      </section>

      <section
        id="what-we-do"
        className="py-8 md:py-16 px-4 md:px-8 bg-gray-100 flex flex-col items-center justify-center"
        data-aos="fade-up"
      >
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-8">What We Do</h2>
          <div className="max-w-4xl mx-auto">
            <p className="text-lg leading-relaxed mb-8">
              Recruiters on our platform not only find candidates but also
              handle the entire application process on behalf of the candidates.
              This includes screening resumes, conducting interviews, and
              coordinating with hiring managers to ensure a seamless recruitment
              experience.
            </p>
            <p className="text-lg leading-relaxed mb-8">
              Our goal is to streamline the recruitment process, save time and
              resources for companies, and ultimately, help them find the best
              talent for their teams. With RecruitKart, recruiting has never
              been easier!
            </p>
          </div>
        </div>
      </section>

      <section
        id="contact"
        className="py-8 md:py-16 px-4 md:px-8 bg-gray-200 flex flex-col items-center justify-center"
        data-aos="fade-up"
      >
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-8">Contact Us</h2>
          <p className="text-lg mb-8">
            Have questions or need assistance? Reach out to us!
          </p>
          <a
            href="mailto:contact@recruitkart.com"
            className="bg-blue-500 text-white hover:bg-blue-400 py-2 px-6 inline-block rounded-full font-semibold transition duration-300"
          >
            Email Us
          </a>
        </div>
      </section>
      <section>
        <Footer />
      </section>
    </div>
  );
};

export default AboutUs;
