import React from "react";

const Login = () => {
  return (
    <div className="flex justify-center items-center h-screen background">
      <h1 className="text-4xl sm:text-6xl md:text-8xl lg:text-9xl font-bold animate-zoom-in-out">
        Coming Soon
      </h1>
    </div>
  );
};

export default Login;
