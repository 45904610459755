import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const testimonials = [
  {
    text: "My experience using hirst.tech to recruit technical candidates has been fabulous primarily for two reasons. Firstly, the ease of using the portal. Secondly, the number of options and filters that the portal provides to explore a gamut of functions. This helps us to get in touch with candidates who match our requirement.",
    logo: "/images/12.jpeg",
  },
  {
    text: "My experience using hirst.tech to recruit technical candidates has been fabulous primarily for two reasons. Firstly, the ease of using the portal. Secondly, the number of options and filters that the portal provides to explore a gamut of functions. This helps us to get in touch with candidates who match our requirement.",
    logo: "/images/13.jpeg",
  },
  {
    text: "My experience using hirst.tech to recruit technical candidates has been fabulous primarily for two reasons. Firstly, the ease of using the portal. Secondly, the number of options and filters that the portal provides to explore a gamut of functions. This helps us to get in touch with candidates who match our requirement.",
    logo: "/images/14.jpg",
  },
];

const TestimonialCarousel = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div className="bg-white p-8">
      <Carousel
        selectedItem={selectedIndex}
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        onChange={(index) => setSelectedIndex(index)}
      >
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-center text-center"
          >
            <p className="text-xl mb-12">{testimonial.text}</p>
            <div className="image-container">
              <img
                src={testimonial.logo}
                alt="Company logo"
                className="w-16 h-16 mb-12"
              />
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default TestimonialCarousel;
