import React from "react";

const talents = [
  {
    name: "Amanda Atkinson",
    position: "Talent Acquisition, Stripe",
    email: "amandaatkinson@gmail.com",
    linkedin: "https://linkedin.com/in/amanda-atkinson",
    image: "/images/emp1.jpg",
    earned: "$XX,XXX",
    leads: "XX",
  },
  {
    name: "Sam Curran",
    position: "Talent Acquisition, Amazon",
    email: "samcurran@gmail.com",
    linkedin: "https://linkedin.com/in/sam-curran",
    image: "/images/emp2.jpg",
    earned: "$XX,XXX",
    leads: "XX",
  },
  {
    name: "Jennifer Lawrence",
    position: "Talent Acquisition, Google",
    email: "jenniferlawrence@gmail.com",
    linkedin: "https://linkedin.com/in/jennifer-lawrence",
    image: "/images/emp3.jpg",
    earned: "$XX,XXX",
    leads: "XX",
  },
];

const RecruiterCards = () => {
  return (
    <div className="p-4">
      <h1 className="text-center text-5xl font-bold mb-12">
        Meet Our Top TA's
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {talents.map((talent, index) => (
          <div
            key={index}
            className="max-w-xs w-full mx-auto border p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105"
          >
            <img
              src={talent.image}
              alt={talent.name}
              className="w-auto h-auto mx-auto mb-4 transition-transform transform hover:scale-110"
            />
            <h2 className="text-xl font-semibold text-center">{talent.name}</h2>
            <p className="text-center">{talent.position}</p>
            <p className="text-center mt-2">{talent.email}</p>
            <p className="text-center mt-2">
              LinkedIn:{" "}
              <a
                href={talent.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500"
              >
                Profile
              </a>
            </p>
            <div className="text-center mt-4">
              <p>Amount Earned: {talent.earned}</p>
              <p>Leads Connected: {talent.leads}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecruiterCards;
