import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const TalentCard = ({ name, image, description, reverse, index }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  return (
    <div
      className={`flex flex-col ${
        reverse ? "lg:flex-row-reverse" : "lg:flex-row"
      } my-8 items-center`}
      data-aos="fade-up"
    >
      <img
        src={image}
        alt={name}
        className="w-full sm:w-64 lg:w-48 h-auto lg:h-48 rounded-lg object-cover mx-4 lg:mx-8 mb-4 lg:mb-0 transform transition-transform duration-300 hover:scale-105"
      />
      <div
        className={`text-left w-full px-4 lg:px-8 ${
          index === 1 ? "mt-4 lg:mt-0" : ""
        }`}
      >
        <h2 className="text-lg sm:text-xl lg:text-2xl font-bold mb-2">
          {name}
        </h2>
        <p className="text-sm sm:text-base lg:text-base break-words">
          {description}
        </p>
      </div>
    </div>
  );
};

export default TalentCard;
