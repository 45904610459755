import React from "react";

const Companycards = () => {
  return (
    <div className="flex flex-col md:flex-row items-center h-auto md:h-screen w-full bg-white">
      <div className="bg-blue-900 text-white p-8 md:p-24 shadow-lg flex flex-col justify-center items-center w-full md:w-1/2">
        <p className="text-lg md:text-xl font-semibold text-center">
          “ RecruitKart has been incredibly effective in connecting us with the
          ideal candidates for the companies. It saves valuable time and effort
          for both recruiters and companies, reducing the hiring cycle and
          enhancing the overall experience. ”
        </p>
        <p className="mt-4 text-base md:text-lg font-bold text-center">
          : Dani
        </p>
      </div>
      <div className="w-full md:w-1/2 h-64 md:h-full flex items-center justify-center">
        <img
          src="/images/employee.webp"
          alt="Customer Service"
          className="w-full h-full object-cover md:w-auto md:h-auto"
        />
      </div>
    </div>
  );
};

export default Companycards;
