import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import TalentCard from "./TalentCard.js";
import useCountUp from "./useCountUp.js";
import { useInView } from "react-intersection-observer";
import Header from "./header.js";
import Footer from "./footer.js";

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  const talents = [
    {
      name: "Amanda Aktinson",
      image: "/images/emp1.jpg",
      description:
        "Our experience with this platform has been exceptional. The user-friendly interface and robust features have streamlined our hiring process, enabling us to find top talent quickly and efficiently. The quality of candidates we’ve found here is unparalleled.",
    },
    {
      name: "Sam Buffay",
      image: "/images/emp2.jpg",
      description:
        "This website has been a game-changer for our recruitment strategy. The comprehensive candidate profiles and powerful search capabilities have saved us countless hours. We’ve filled critical positions faster and with highly qualified candidates. Highly recommend!",
    },
    {
      name: "Amanda Aktinson",
      image: "/images/emp3.jpg",
      description:
        "As a recruiter in the healthcare industry, finding specialized talent can be challenging. This platform has exceeded our expectations by providing access to a vast pool of qualified candidates. The support team is also fantastic, always ready to assist with any queries. It’s a must-have tool for any recruiter.",
    },
  ];

  const { ref: recruitersRef, inView: recruitersInView } = useInView();
  const { ref: companiesRef, inView: companiesInView } = useInView();
  const { ref: jobsRef, inView: jobsInView } = useInView();

  const recruitersCount = useCountUp(recruitersInView ? 500 : 0, 1000);
  const companiesCount = useCountUp(companiesInView ? 200 : 0, 1000);
  const jobsCount = useCountUp(jobsInView ? 1000 : 0, 1000);

  return (
    <div>
      <Header />
      <div className="bg-white">
        <section
          className="relative flex flex-col items-center justify-center text-center h-screen bg-cover bg-center"
          style={{ backgroundImage: 'url("/images/background image.jpg")' }}
        >
          <div className="absolute inset-0 bg-black opacity-80"></div>
          <div className="relative z-10" data-aos="fade-up">
            <h1 className="text-7xl font-bold text-white mb-12">
              Get exactly what you need
            </h1>
            <p className="text-xl text-white mb-32 p-2 ">
              Collaborate with us and improve your hiring outcomes with our
              platform.
            </p>
            <button className="bg-blue-500 text-white px-6 py-3 rounded-full font-medium hover:bg-blue-600">
              Get Started
            </button>
          </div>
        </section>

        <section className="text-center py-8">
          <h2 className="text-5xl font-bold mb-12 mt-8 p-2" data-aos="fade-up">
            Where Company and Talent Acquisition Connects
          </h2>
          <div className="max-w-4xl mx-auto px-4" data-aos="fade-up">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="md:w-3/6 p-4" data-aos="fade-up">
                <h3 className="text-4xl font-bold mb-4">Need Talent?</h3>
                <p className="font-medium pl-4 text-left">
                  Transform your hiring approach with our innovative solutions.
                  Partner with us to leverage cutting-edge recruitment
                  technology ensuring you find the perfect fit for your
                  organization. Experience the future of hiring and build a
                  stronger team with ease.
                </p>
              </div>
              <div className="md:w-2/5 p-4" data-aos="fade-up">
                <img
                  src="/images/1.jpg"
                  alt="Teamwork"
                  className="rounded-3xl shadow-md animate-zoom-out-in hover:animate-zoom-in-out"
                />
              </div>
            </div>
          </div>
        </section>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 text-center bg-blue-900 text-white p-8 sm:p-12 md:p-16 lg:p-24 mb-0 mt-12">
          <div ref={recruitersRef}>
            <h2 className="text-4xl sm:text-5xl md:text-7xl lg:text-9xl font-bold">
              {recruitersCount}+
            </h2>
            <p className="mt-4 text-base sm:text-lg md:text-xl lg:text-2xl">
              Recruiters
            </p>
          </div>
          <div ref={companiesRef}>
            <h2 className="text-4xl sm:text-5xl md:text-7xl lg:text-9xl font-bold">
              {companiesCount}+
            </h2>
            <p className="mt-4 text-base sm:text-lg md:text-xl lg:text-2xl">
              Companies
            </p>
          </div>
          <div ref={jobsRef}>
            <h2 className="text-4xl sm:text-5xl md:text-7xl lg:text-9xl font-bold">
              {jobsCount}+
            </h2>
            <p className="mt-4 text-base sm:text-lg md:text-xl lg:text-2xl">
              Jobs
            </p>
          </div>
        </div>

        <section className="py-8 mb-4 p-16 bg-blue-900">
          <div className="overflow-hidden relative">
            <div className="flex space-x-32 flex-nowrap animate-slide-in-out-slow sm:animate-slide-in-out-fast">
              <img src="/images/logo1.jpg" alt="1" className="w-32 h-32" />
              <img src="/images/logo3.jpg" alt="3" className="w-32 h-32" />
              <img src="/images/logo4.jpg" alt="4" className="w-32 h-32" />
              <img src="/images/logo6.jpg" alt="6" className="w-32 h-32" />
              <img src="/images/logo7.jpg" alt="7" className="w-32 h-32" />
              <img src="/images/logo9.jpg" alt="9" className="w-32 h-32" />
              <img src="/images/logo1.jpg" alt="1" className="w-32 h-32" />
              <img src="/images/logo3.jpg" alt="3" className="w-32 h-32" />
              <img src="/images/logo4.jpg" alt="4" className="w-32 h-32" />
              <img src="/images/logo6.jpg" alt="6" className="w-32 h-32" />
              <img src="/images/logo7.jpg" alt="7" className="w-32 h-32" />
              <img src="/images/logo9.jpg" alt="9" className="w-32 h-32" />
            </div>
          </div>
        </section>

        <section className="py-8">
          <h1
            className="text-5xl font-bold text-center mb-12"
            data-aos="fade-up"
          >
            Meet Our Top TA's
          </h1>
          <div className="max-w-7xl mx-auto px-4" data-aos="fade-up">
            {talents.map((talent, index) => (
              <TalentCard
                key={index}
                name={talent.name}
                image={talent.image}
                description={talent.description}
                reverse={index % 2 !== 0}
                index={index}
              />
            ))}
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
