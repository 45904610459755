import React, { useEffect } from "react";
import Header from "./header";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "./footer";
import TestimonialCarousel from "./recruitermain";
import useCountUp from "./useCountUp.js";
import { useInView } from "react-intersection-observer";
import RecruiterCards from "./recruitercard.js";
import TechFunctionsSlider from "./recruiterslider.js";

const RecruiterHome = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  const { ref: recruitersRef, inView: recruitersInView } = useInView();
  const { ref: companiesRef, inView: companiesInView } = useInView();
  const { ref: jobsRef, inView: jobsInView } = useInView();

  const recruitersCount = useCountUp(recruitersInView ? 500 : 0, 1000);
  const companiesCount = useCountUp(companiesInView ? 200 : 0, 1000);
  const jobsCount = useCountUp(jobsInView ? 1000 : 0, 1000);

  return (
    <div className="overflow-x-hidden">
      <Header />
      <div className="bg-white">
        <section
          className="relative flex flex-col items-center justify-center text-center h-screen bg-cover bg-center"
          style={{ backgroundImage: 'url("/images/recruiter page.jpeg")' }}
        >
          <div className="absolute inset-0 bg-black opacity-80"></div>
          <div className="relative z-10" data-aos="fade-up">
            <h1 className="text-6xl font-bold text-white mb-12 p-4">
              You’ve a Job, we’re here to help you hire a right candidate
            </h1>
            <p className="text-xl text-white mb-32 p-2">
              Partner with us to enhance your recruitment efforts and achieve
              superior hiring results through our innovative platform
            </p>
            <button className="bg-blue-500 text-white px-6 py-3 rounded-full font-medium hover:bg-blue-600">
              Get Started
            </button>
          </div>
        </section>
        <section className="py-0" data-aos="fade-up">
          <TestimonialCarousel />
        </section>
        <div
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 text-center bg-blue-900 text-white p-8 sm:p-12 md:p-16 lg:p-24 mb-0"
          data-aos="fade-up"
        >
          <div ref={recruitersRef}>
            <h2 className="text-4xl sm:text-5xl md:text-7xl lg:text-8xl font-bold">
              {recruitersCount}+
            </h2>
            <p className="mt-4 text-base sm:text-lg md:text-xl lg:text-2xl">
              Recruiters
            </p>
          </div>
          <div ref={companiesRef}>
            <h2 className="text-4xl sm:text-5xl md:text-7xl lg:text-8xl font-bold">
              {companiesCount}+
            </h2>
            <p className="mt-4 text-base sm:text-lg md:text-xl lg:text-2xl">
              Companies
            </p>
          </div>
          <div ref={jobsRef}>
            <h2 className="text-4xl sm:text-5xl md:text-7xl lg:text-8xl font-bold">
              {jobsCount}+
            </h2>
            <p className="mt-4 text-base sm:text-lg md:text-xl lg:text-2xl">
              Jobs
            </p>
          </div>
        </div>

        <section className="py-8 mb-4 p-16 bg-blue-900">
          <div className="overflow-hidden relative">
            <div className="flex space-x-32 flex-nowrap animate-slide-in-out-slow sm:animate-slide-in-out-fast">
              <img src="/images/logo1.jpg" alt="1" className="w-32 h-32" />
              <img src="/images/logo3.jpg" alt="3" className="w-32 h-32" />
              <img src="/images/logo4.jpg" alt="4" className="w-32 h-32" />
              <img src="/images/logo6.jpg" alt="6" className="w-32 h-32" />
              <img src="/images/logo7.jpg" alt="7" className="w-32 h-32" />
              <img src="/images/logo9.jpg" alt="9" className="w-32 h-32" />
              <img src="/images/logo1.jpg" alt="1" className="w-32 h-32" />
              <img src="/images/logo3.jpg" alt="3" className="w-32 h-32" />
              <img src="/images/logo4.jpg" alt="4" className="w-32 h-32" />
              <img src="/images/logo6.jpg" alt="6" className="w-32 h-32" />
              <img src="/images/logo7.jpg" alt="7" className="w-32 h-32" />
              <img src="/images/logo9.jpg" alt="9" className="w-32 h-32" />
            </div>
          </div>
        </section>
        <section data-aos="fade-up">
          <RecruiterCards />
        </section>
        <section data-aos="fade-up">
          <TechFunctionsSlider />
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default RecruiterHome;
