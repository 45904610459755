import React, { useEffect } from "react";
import Header from "./header";
import AOS from "aos";
import "aos/dist/aos.css";
import useCountUp from "./useCountUp.js";
import { useInView } from "react-intersection-observer";
import JobPostAd from "./jobpost.js";
import Companycards from "./companycards.js";
import Footer from "./footer.js";

const Companyhome = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  const { ref: recruitersRef, inView: recruitersInView } = useInView();
  const { ref: companiesRef, inView: companiesInView } = useInView();
  const { ref: jobsRef, inView: jobsInView } = useInView();

  const recruitersCount = useCountUp(recruitersInView ? 500 : 0, 1000);
  const companiesCount = useCountUp(companiesInView ? 200 : 0, 1000);
  const jobsCount = useCountUp(jobsInView ? 1000 : 0, 1000);

  return (
    <div>
      <Header />
      <div className="bg-white">
        <section
          className="relative flex flex-col items-center justify-center text-center h-screen bg-cover bg-center"
          style={{ backgroundImage: 'url("/images/company page.jpg")' }}
        >
          <div className="absolute inset-0 bg-black opacity-70"></div>
          <div className="relative z-10" data-aos="fade-up">
            <h1 className="text-3xl md:text-6xl font-bold text-white mb-8 md:mb-12 p-2">
              You’ve got a position to fill, and we’re here to connect you with
              the ideal candidate
            </h1>
            <p className="text-lg md:text-xl text-white mb-8 md:mb-12 p-2">
              Collaborate with us to elevate your recruitment process and secure
              top talent effortlessly through our cutting-edge platform.
            </p>
            <button className="bg-blue-500 text-white px-6 py-3 rounded-full font-medium hover:bg-blue-600">
              Get Started
            </button>
          </div>
        </section>
        <section data-aos="fade-up">
          <JobPostAd />
        </section>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 text-center bg-blue-900 text-white p-8 sm:p-12 md:p-16 lg:p-24 mb-0 mt-12">
          <div ref={recruitersRef}>
            <h2 className="text-4xl sm:text-5xl md:text-7xl lg:text-9xl font-bold">
              {recruitersCount}+
            </h2>
            <p className="mt-4 text-base sm:text-lg md:text-xl lg:text-2xl">
              Recruiters
            </p>
          </div>
          <div ref={companiesRef}>
            <h2 className="text-4xl sm:text-5xl md:text-7xl lg:text-9xl font-bold">
              {companiesCount}+
            </h2>
            <p className="mt-4 text-base sm:text-lg md:text-xl lg:text-2xl">
              Companies
            </p>
          </div>
          <div ref={jobsRef}>
            <h2 className="text-4xl sm:text-5xl md:text-7xl lg:text-9xl font-bold">
              {jobsCount}+
            </h2>
            <p className="mt-4 text-base sm:text-lg md:text-xl lg:text-2xl">
              Jobs
            </p>
          </div>
        </div>
        <div className="text-center mb-8 mt-8 px-4">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4">
            Streamline your recruitment process and save valuable time and
            effort.
          </h1>
          <p className="text-lg md:text-xl max-w-2xl mx-auto">
            Unearth the ideal candidate without spending your entire day.
            RecruitKart's user-friendly platform simplifies sourcing, making
            your hiring process efficient and hassle-free.
          </p>
        </div>
        <section data-aos="fade-up">
          <Companycards />
        </section>
      </div>
      <div
        className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8 p-8"
        data-aos="fade-up"
      >
        <div className="bg-blue-900 text-white p-8 md:p-16 rounded-2xl">
          <h2 className="text-2xl md:text-3xl font-semibold mb-4 md:mb-8">
            Create your free account
          </h2>
          <p>
            Simply use your email address to create an account and start
            crafting your job post.
          </p>
        </div>
        <div className="bg-blue-700 text-white p-8 md:p-16 rounded-2xl">
          <h2 className="text-2xl md:text-3xl font-semibold mb-4 md:mb-8">
            Build your Job Post
          </h2>
          <p>
            Enter job details such as job description, qualifications, and
            location, then finalize your job post with a single click.
          </p>
        </div>
        <div className="bg-blue-900 text-white p-8 md:p-16 rounded-2xl">
          <h2 className="text-2xl md:text-3xl font-semibold mb-4 md:mb-8">
            Post your Job
          </h2>
          <p>
            Once your job is posted, leverage our cutting-edge tools to discover
            your ideal talent.
          </p>
        </div>
      </div>
      <section data-aos="fade-up">
        <Footer />
      </section>
    </div>
  );
};

export default Companyhome;
